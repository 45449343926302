import React from 'react';

const HowToWork = ({ children, className = '' }) => (
  <section id="how-to" className="pt-10 lg:mb-10">
    <div className="container mx-auto text-center">
      <h1 className="flex text-3xl lg:text-5xl xl:text-5xl font-bold leading-none justify-center">
        How to work with us
      </h1>
      <div className="flex flex-col sm:flex-row sm:-mx-3 my-5">
        <div class="elementor-icon-box-content px-12 flex-1">
          <h6 className="my-3 text-xl font-semibold leading-tight text-primary">
            <span>Step 1: Initiate a discovery session</span>
          </h6>
          <p class="elementor-icon-box-description block">
            Embark on your journey with a discovery call where we delve into the heart of your
            business needs. Together, we'll map out your vision, discuss the ideal team composition,
            define success parameters, and align on timelines and budget.
          </p>
        </div>
        <div class="elementor-icon-box-content px-1 flex-1">
          <h6 className="my-3 text-xl font-semibold leading-tight text-primary">
            <span>Step 2: Craft custom team blueprint</span>
          </h6>
          <p class="elementor-icon-box-description block">
            In this stage we transform our conversation into a tailored project blueprint. We'll
            pinpoint the perfect engagement model, handpick your specialized team from our pool of
            top-tier talent & seamlessly integrate them into your mission.
          </p>
        </div>
        <div class="elementor-icon-box-content px-12 flex-1">
          <h6 className="my-3 text-xl font-semibold leading-tight text-primary">
            <span>Step 3: Launch & elevate your product</span>
          </h6>
          <p class="elementor-icon-box-description block">
            With the strategy set and team in place, we hit the ground running. As development
            progresses, we maintain transparent communication, measure against benchmarks, and
            dynamically adjust to ensure your product not only launches but soars.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default HowToWork;
