export default [
  {
    title: 'Fast, smart and agile',
    content:
      "It would have taken months to build the product team that we inherited overnight from SGI. They're built for startup speed, and they have consistently delivered a well-engineered product for us. Outsourcing our task to SGI team was a blessing",
    customerName: 'Sharad Gurung',
    customerTitle: 'Director at Delta Core',
    customerImage: 'https://placeimg.com/150/150/people',
  },
  {
    title: 'Professional team that earned our trust',
    content:
      'Being a US based cybersecurity company, we were very impressed with the technical expertise of employees provided by SG Innovations.SGI delivered the best possible solution with their top-tier engineering talents that understand technologies to meet business needs.',
    customerName: 'Jennifer',
    customerTitle: 'Sphere',
    customerImage: 'https://placeimg.com/150/150/people',
  },
];
