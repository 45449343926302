import React from 'react';
import '../css/banner.css';

const BannerImage = () => (
  <div>
    <img src={'../../../banner.png'} width="100%" />
    {/* <h1 className="text-4xl lg:text-9xl xl:text-9xl font-bold leading-none overlay-text"> */}
    <h1 className="font-bold leading-none overlay-text">
      <p>Scale your team with our</p>
      <br />
      <p>talented engineers</p>
    </h1>
  </div>
);

export default BannerImage;
